@tailwind base;
@tailwind components;

/* base combined text classes */
.text-2xs {
  @apply text-xxs leading-3 tracking-lg;
}

.text-xs {
  @apply text-xsmall leading-4 tracking-lg;
}

.text-sm {
  @apply text-small leading-5 tracking-lg;
}

.text-base {
  @apply text-b leading-6 tracking-lg;
}

.text-lg {
  @apply text-large leading-7 tracking-base;
}

.text-xlg {
  @apply text-xlarge leading-8 tracking-base;
}

.text-2xlg {
  @apply text-xxl leading-10 tracking-base;
}

.text-3xlg {
  @apply text-xxxl leading-10 tracking-sm;
}

.text-4xlg {
  line-height: 64px;
  @apply text-xxxxl tracking-sm;
}

.text-5xlg {
  @apply text-xxxxxl leading-10 tracking-sm;
}

@tailwind utilities;

:root {
  --tooltipArrowHeight: 40px;
  --tooltipArrowWidth: 40px;
}

@layer base {
  h4 {
    @apply font-tommyBold text-2xl;
  }
}

@font-face {
  font-family: 'Tommy regular';
  font-weight: 400;
  src: url('../public/fonts/made-tommy-soft-regular.woff2') format('woff2'),
    url('../public/fonts/made-tommy-soft-regular.woff') format('woff');
}

@font-face {
  font-family: 'Tommy bold';
  font-weight: 700;
  src: url('../public/fonts/made-tommy-soft-bold.woff2') format('woff2'),
    url('../public/fonts/made-tommy-soft-bold.woff') format('woff');
}

@font-face {
  font-family: 'Tommy black';
  font-weight: 900;
  src: url('../public/fonts/made-tommy-soft-black.woff2') format('woff2'),
    url('../public/fonts/made-tommy-soft-black.woff') format('woff');
}

@font-face {
  font-family: 'Lab regular';
  font-weight: 400;
  src: url('../public/fonts/LabGrotesque-Regular.woff2') format('woff2'),
    url('../public/fonts/LabGrotesque-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Lab medium';
  font-weight: 700;
  src: url('../public/fonts/LabGrotesque-Medium.woff2') format('woff2'),
    url('../public/fonts/LabGrotesque-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Lab bold';
  font-weight: 900;
  src: url('../public/fonts/LabGrotesque-Bold.woff2') format('woff2'),
    url('../public/fonts/LabGrotesque-Bold.woff') format('woff');
}

@font-face {
  font-family: 'SfProTextRegular';
  font-weight: 400;
  src: url('../public/fonts/sf-pro-text-regular.woff') format('woff');
}

@font-face {
  font-family: 'SfProTextSemiBold';
  font-weight: 600;
  src: url('../public/fonts/sf-pro-text-semibold.woff') format('woff');
}

@font-face {
  font-family: 'SfProTextBold';
  font-weight: 700;
  src: url('../public/fonts/sf-pro-text-bold.woff') format('woff');
}

@font-face {
  font-family: 'SfProTextBlack';
  font-weight: 900;
  src: url('../public/fonts/sf-pro-text-heavy.woff') format('woff');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: SfProTextRegular, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #f5f5f5;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.button-red {
  background-color: #c50e11;
  color: #ffffff;
  transition: background-color 0.2s linear;
}

.button-red:hover {
  background-color: #a50f11;
}

@media (min-width: 768px) {
  .container {
    max-width: 98% !important;
  }
}

/* custom classes for toast */
.Toastify__toast-icon {
  width: 16px !important;
}

.Toastify__progress-bar--success {
  background: #064e3b !important;
}

.Toastify__progress-bar--error {
  background: #86090b !important;
}

.bg-toastSuccess {
  background-color: #d1fae5;
}

.bg-toastError {
  background-color: #fee2e2;
}

.text-toastSuccess,
.bg-toastSuccess button.Toastify__close-button--colored {
  color: #064e3b;
}

.text-toastError,
.bg-toastError button.Toastify__close-button--colored {
  color: #86090b;
}

button.Toastify__close-button--colored {
  opacity: 1;
  margin-top: 6px;
}

/* custom classes for material/tailwind input */
.mt-input-outline-red-500,
.mt-input-outline-red-500:focus,
.mt-input-outline-red-500:focus + label,
.mt-input-outline-red-500:focus + label:before,
.mt-input-outline-red-500:focus + label:after,
.mt-input-outline-error:not(:placeholder-shown) + label,
.mt-input-outline-error:not(:placeholder-shown) + label:after,
.mt-input-outline-error:not(:placeholder-shown) + label:before {
  border-color: #e00b3e !important;
  color: #e00b3e !important;
}

.customDatePicker .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.customDatePicker .react-datepicker__month-text--selected,
.customDatePicker .react-datepicker__month-text--in-selecting-range,
.customDatePicker .react-datepicker__month-text--in-range,
.customDatePicker .react-datepicker__quarter-text--selected,
.customDatePicker .react-datepicker__quarter-text--in-selecting-range,
.customDatePicker .react-datepicker__quarter-text--in-range,
.customDatePicker .react-datepicker__year-text--selected,
.customDatePicker .react-datepicker__year-text--in-selecting-range,
.customDatePicker .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  @apply bg-red-500 text-white;
}
.customDatePicker .react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.customDatePicker .react-datepicker__month-text--selected:hover,
.customDatePicker .react-datepicker__month-text--in-selecting-range:hover,
.customDatePicker .react-datepicker__month-text--in-range:hover,
.customDatePicker .react-datepicker__quarter-text--selected:hover,
.customDatePicker .react-datepicker__quarter-text--in-selecting-range:hover,
.customDatePicker .react-datepicker__quarter-text--in-range:hover,
.customDatePicker .react-datepicker__year-text--selected:hover,
.customDatePicker .react-datepicker__year-text--in-selecting-range:hover,
.customDatePicker .react-datepicker__year-text--in-range:hover {
  @apply bg-red-700;
}
.customDatePicker .react-datepicker__day--keyboard-selected,
.customDatePicker .react-datepicker__month-text--keyboard-selected,
.customDatePicker .react-datepicker__quarter-text--keyboard-selected,
.customDatePicker .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  @apply bg-red-500 text-white;
}
.customDatePicker .react-datepicker__day--keyboard-selected:hover,
.customDatePicker .react-datepicker__month-text--keyboard-selected:hover,
.customDatePicker .react-datepicker__quarter-text--keyboard-selected:hover,
.customDatePicker .react-datepicker__year-text--keyboard-selected:hover {
  @apply bg-red-700;
}

button:focus {
  @apply outline-none;
}
